import React from 'react'
import {navigation,navLinks,navLinkItem,navLinkText,active} from './navigation.module.css'
import Gap from './gap'
import { Link } from 'gatsby'

const Navigation = ({currentPage}) => {
    const activeClass = `${navLinkItem} ${active}`;
    const inActiveClass = `${navLinkItem}`;
    return (
        <>
        <Gap height={30}/>
        <div className={navigation}>
            <ul className={navLinks}>
                <Link to='/' className={(currentPage === "blog")?activeClass:inActiveClass}>
                    <li>
                        <p className={navLinkText}>Blog</p>
                    </li>
                </Link>
                <Link to='/project' className={(currentPage === "project")?activeClass:inActiveClass}>
                    <li className={navLinkItem}>
                        <p className={navLinkText}>Project</p>
                    </li>
                </Link>
                <Link to='/office' className={(currentPage === "office")?activeClass:inActiveClass}>
                    <li>
                        <p className={navLinkText}>Office</p>
                    </li>
                </Link>
            </ul>
        </div>
        </>
    )
}

export default Navigation
