import React from 'react'
import Profile from './profile'
import './layout.css'
import Navigation from './navigation'
import Gap from './gap'


const Layout = ({currentPage,children}) => {
    return (
        <div className='main-app-wrapper'>
            <div className='profile-wrapper'>
                <Profile/>
            </div>
            <div className='content-wrapper'>
                <Navigation currentPage={currentPage}/>
                <Gap height={30}/>
                {children}
            </div>
        </div>
    )
}

export default Layout
