import React, { useEffect, useState } from 'react'
import {blogWrapper,blogThumbnail,blogContent,imgThumbnail,contentDate,contentTitle,contentPreview,contentDivider,show} from './blog.module.css'
import Gap from './gap'
import { Link } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

const Blog = ({blogs}) => {

    const [isShowBlog,setIsShowBlog] = useState(false);

    useEffect(()=>{
        setTimeout(()=>{
            setIsShowBlog(true);
        },500)
    },[]);

    return (
        <>
        {blogs.map(blog=>{
            var blog = blog.node;
            const image = getImage(blog.heroImage);
            return(
                <Link key={blog.id} to={`/${blog.slug}`} className={(isShowBlog)?`${blogWrapper} ${show}`:`${blogWrapper}`}>
                    <div className={blogThumbnail}>
                        <GatsbyImage className={imgThumbnail} image={image} alt='blog-thumbnail'/>
                    </div>
                    <div className={blogContent}>
                        <p className={contentDate}>{blog.date}</p>
                        <Gap height={5}/>
                        <h3 className={contentTitle}>{blog.title}</h3>
                        <Gap height={5}/>
                        <p className={contentPreview}>
                            {blog.description.childMarkdownRemark.excerpt}
                        </p>
                        <Gap height={10}/>
                        <hr className={contentDivider}/>
                    </div>
                </Link>
            );
        })}
        </>
    )
}

export default Blog
