import React from 'react'
import Layout from '../components/layout'
import Blog from '../components/blog'
import SEO from '../components/seo'
import { graphql } from 'gatsby'

const IndexPage = ({data}) => {
  return (
    <Layout currentPage="blog">
      <SEO />
      <Blog blogs={data.allContentfulBlog.edges}/>
    </Layout>
  )
}

export const data = graphql`
  query{
    allContentfulBlog(sort:{fields:date,order:DESC}){
      edges{
        node{
          id
          title
          slug
          date(formatString:"DD MMMM, YYYY")
          description{
            childMarkdownRemark{
              excerpt
            }
          }
          heroImage{
            gatsbyImageData
          }
        }
      }
    }
  }
`;

export default IndexPage
